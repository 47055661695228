
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Login, LoginValidObj } from '../../types/user'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private loginForm = {
    loginId: '',
    password: '',
    verifyCode: ''
  }

  private loginSwitch = 'PC'
  private loading = false
  private errText = ''
  private errNum = 0
  private loginRules = {
    loginId: [
      { required: true, message: '请输入账号' }
    ],
    password: [
      { required: true, message: '请输入密码' }
    ],
    verifyCode: [
      { required: true, message: '请输入验证码' }
    ]
  }

  private verifyCode = this.$apis.user.getVerifyCode + '?d=' + Number(new Date())

  get isJiaXing () {
    return window.location.href.indexOf('zjronglin') >= 0
  }

  get isRuiJing () {
    return window.location.href.indexOf('raising') >= 0 || window.location.href.indexOf('rjcs') >= 0
  }

  $refs!: {
    loginModule: ElForm;
  }

  created () {
    this.getErrNum()
  }

  verifyCodeUpdate () {
    this.verifyCode = this.$apis.user.getVerifyCode + '?d=' + Number(new Date())
  }

  getErrNum () {
    this.$axios.get(this.$apis.common.failuresNum).then(res => {
      this.errNum = res
    })
  }

  login () {
    this.loading = true
    this.$axios.post<Login>(this.$apis.user.login, this.loginForm).then(res => {
      this.errText = ''
      window.sessionStorage.setItem('token', res.token)
      window.sessionStorage.setItem('customerCode', res.customer.customerCode)
      window.sessionStorage.setItem('isImgHtml', res.customer.isImgHtml || '')
      window.sessionStorage.setItem('userInfo', JSON.stringify(res.user))
      if (res.customer.isImgHtml) {
        window.location.href = window.location.origin + res.customer.isImgHtml
      } else {
        this.$router.push('/')
      }
    }).catch((err) => {
      this.errText = err.message
      this.errNum++
      this.verifyCodeUpdate()
    }).finally(() => {
      this.loading = false
    })
  }

  // // 路由跳转，2：一体化平台；1：资源一张图（天人山水定制，其他客户无效），0：正常跳转后台页面
  // routeJump (isImgHtml: string, customerCode: string) {
  //   if (isImgHtml === '2') {
  //     if (customerCode === 'customer_yzby') {
  //       window.location.href = window.location.origin + '/integrated3.0/'
  //     } else {
  //       window.location.href = window.location.origin + '/integrated/'
  //     }
  //   } else if (isImgHtml === '3') {
  //     window.location.href = window.location.origin + '/integrated2.0/'
  //   } else if (isImgHtml === '1' && customerCode === 'customer_trss') {
  //     this.$router.push('/workbench')
  //   } else {
  //     this.$router.push('/')
  //   }
  // }

  handleLogin () {
    this.$refs.loginModule.validate((valid: boolean, obj: LoginValidObj) => {
      if (valid) {
        this.login()
      } else {
        this.errText = obj.loginId ? obj.loginId[0].message
          : obj.password ? obj.password[0].message
            : obj.verifyCode ? obj.verifyCode[0].message : ''
      }
    })
  }
}
